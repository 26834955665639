










import { Component, Vue } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import { State } from "vuex-class";
import User from "@/entity/User";
import { Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import UserService from "@/services/UserService";

@Component({
  components: { SectionTitle },
})
export default class ProfileView extends Vue {
  @State("user", { namespace: "auth" })
  user!: User;

  enableEmailNotifications = false;

  async updateEmailNotifications() {
    await UserService.updateNotificationSettings({
      enableEmailNotifications: this.enableEmailNotifications,
    });
  }

  debounceUpdate = debounce(() => {
    this.updateEmailNotifications();
  }, 500);

  @Watch("enableEmailNotifications")
  onUserChanged() {
    this.debounceUpdate();
  }

  mounted() {
    this.enableEmailNotifications = this.user.enableEmailNotifications;
  }
}
